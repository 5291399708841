/* INPUTS */

.mat-form-field {
  &-appearance-outline {
    .mat-form-field-outline {
      color: $primary-color;
    }

    &.mat-form-field-disabled {
      .mat-form-field-outline {
        color: $disabled-color;
      }
    }
  }

  &.filter {
    .mat-form-field-ripple {
      bottom: 0;
      height: 2px;
      background-color: $primary-color
    }
  }

}

.mat-select-panel-wrap {
  .mat-select-panel {
    .search-input {
      display: flex;
      height: 42px;
      align-items: center;
      padding: 0 10px;
      gap: 10px;

      input {
        flex-grow: 1;
        height: 70%;
      }
    }
  }
}