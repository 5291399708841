/* Mat table */
.campaigns-follow-table {

    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type {
      padding-right: 10px;
    }
  
    th.mat-header-cell,
    td.mat-cell {
      padding: 0 10px;
    }
  
    .mat-cell {
      font-size: 12px;
    }
  
    .mat-column-urlShortName {
      max-width: 600px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .mat-column-createdDate {
      min-width: 62px;
    }
  }