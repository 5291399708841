/* Buttons styles*/

.btn {
    height: 40px;
    &.info {

    }
    &.success {

    }
    &.warning {

    }
}