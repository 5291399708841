/* Colors */

$white-color: #fff;
$error-color: #f44336;
$success-color: rgb(77, 177, 77);
$disabled-color: rgba(0, 0, 0, 0.06);

$text-color: #726059;
$primary-color: #4472c4;
$primary-color-medium: #6a8bc5;
$primary-color-light: #819bc7;

$background-color-light: #F7F8FC;

