// Notifications 
.mat-snack-bar-container {
    simple-snack-bar {
      ::before {
        font-size: 24px;
        margin-right: 10px;
      }
      span {
        display: flex;
        text-align: left;
      }
    }
  }
  
  .snack-info {
    color: $primary-color;
    background-color: $background-color-light;
    text-align: center;
    border: 1px solid $primary-color;
    simple-snack-bar {
      ::before {
        font-family: "Material Icons";
        content: "\e88f";
      }
    }
  
  }
  
  .snack-error {
    color: $error-color;
    background-color: $white-color;
    text-align: center;
    border: solid 1px $error-color;
  
    simple-snack-bar {
      ::before {
        font-family: "Material Icons";
        content: "\e001";
      }
    }
  }
  
  .snack-success {
    color: $success-color;
    background-color: $white-color;
    border: 1px solid $success-color;
  
    simple-snack-bar {
      ::before {
        font-family: "Material Icons";
        content: "\e86c";
      }
    }
  }
  
  .snack-required {
    color: $white-color;
    background-color: $error-color;
    border: 1px solid $error-color;
  
    simple-snack-bar {
      ::before {
        font-family: "Material Icons";
        content: "\e001";
      }
    }
  }