.generated-url-dialog-container {
    width: 800px;
  
    h1 {
      color: $primary-color;
      font-size: 22px;
      text-transform: uppercase;
    }
  
    h1::first-letter {
      font-weight: bold;
      font-size: 28px;
    }
    .mat-dialog-content {
      max-height: fit-content;
    }
  
    .mat-form-field {
      width: 100%;
  
      .mat-form-field-wrapper {
        padding-bottom: 1em;
      }
  
      mat-icon {
        cursor: pointer;
      }
    }
    .campaign-url-container {
      .mat-form-field {
        &-flex {
          font-size: smaller;
        }
      }
    }
  
    .tracked-url-container {
  
      .mat-form-field {
        font-size: 12px;
        width: 32%;
  
        &-infix {
          input {
            max-width: 98%;
          }
        }
  
        .mat-form-field-outline {
          color: $primary-color;
        }
  
        .mat-form-field-appearance-outline.mat-form-field-outline {
          color: $primary-color;
        }
  
        &.tracked-url {
          width: 100%;
  
          .mat-form-field-wrapper {
            margin-bottom: 0;
            padding-bottom: 5px;
          }
  
          .mat-form-field-outline {
            color: $disabled-color;
          }
        }
      }
    }
  
    .mat-dialog-actions {
      justify-content: flex-end;
    }
  }
  