/* You can add global styles to this file, and also import other style files */

// import directories
@import 'assets/styles/1-helpers/helpers-dir';
@import 'assets/styles/2-base/base-dir';
@import 'assets/styles/3-components/components-dir';

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import '~@aws-amplify/ui-angular/theme.css';

:root {
  --amplify-primary-color: #4472c4;
  --amplify-primary-tint: #4472c4;
  --amplify-primary-shade: #4472c4;
  --container-min-height: auto !important;  
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  background-color: $white-color;
  color: $text-color;
}

.container {
  max-width: 1600px;
  width: 90%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}







